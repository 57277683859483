<template>
  <div :class="contents && contents.length ? 'parent-top' : ''">
    <div v-if="showLoading && !(contents && contents.length)" class="placeholder">
      <Placeholder :title="title" :noOfDisplay="noOfDisplay" :gutter="placeholdergutter" />
    </div>
    <div class="slider-div" v-else-if="showSlide">
      <div
        class="col-md-6 col-lg-6 col-xlg-6 vlt-div-title"
        :class="
          category.seeAllLink && showSeeAllBtn
            ? 'col-xs-6 col-sm-6'
            : 'col-xs-12 col-sm-12'
        "
      >
        <div class="section-title">
          <h2 class="sec-title">{{ title }}</h2>
        </div>
      </div>
      <div
        class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xlg-6"
        style="padding: 0px"
        v-if="category.seeAllLink && showSeeAllBtn"
      >
        <div class="see-all">
          <router-link
            :to="{
              name: 'ContentList',
              params: { contentType: category.seeAllLink.toLowerCase() },
            }"
          >
            <div class="see-all-link">
              <p>See All</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="slider-head">
        <div class="slide-head-btns">
          <div class="clearfix"></div>
        </div>
      </div>
      <div class="slider-carousel" ref="sliderElement">
        <!-- v-if="sliderArrow" -->
        <div
          class="slide-controls controls"
          :id="`slide-${sliderName}-controls`"
          :style="{
            top: buttonTop,
          }"
        >
          <div class="btn-slide-controls prev" aria-label="left button">
            <img
              v-if="!mobileView"
              :class="!sliderArrowLeft ? 'prev-disabled' : 'prev-img'"
              src="@/assets/icons/ico-prev.svg"
              alt="icon-prev"
              loading="lazy"
              :disabled="sliderArrowLeft"
              @click="actLeftArrow"
            />
          </div>
          <div
            class="btn-slide-controls next"
            id="next"
            aria-label="right button"
          >
            <img
              v-if="!mobileView"
              :class="!sliderArrowRight ? 'next-disabled' : 'next-img'"
              @click="actRightArrow"
              src="@/assets/icons/ico-next.svg"
              alt="icon-next"
              loading="lazy"
              :disabled="sliderArrowRight"
            />
          </div>
        </div>
        <div class="car-slider" :id="`car-slider-${sliderName}`">
          <div
            v-for="(content, i) in contents"
            :key="content.contentid"
            class="car-slide"
          >
            <div>
              <content-card
                v-if="i === 0"
                :continueWatching="isContinueWatching"
                :content="content"
                :contentId="content.contentid"
                :orientation="category.displayType"
                :showInfo="showInfo"
                :showContentDetailEvent="() => showDetail(content)"
                :lazyObserver="lazyObserver"
                :defaultImage="defaultImage"
                :positionButtons="positionButtons"
              />
              <content-card
                v-else
                :continueWatching="isContinueWatching"
                :content="content"
                :contentId="content.contentid"
                :orientation="category.displayType"
                :showInfo="showInfo"
                :showContentDetailEvent="() => showDetail(content)"
                :lazyObserver="lazyObserver"
                :defaultImage="defaultImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { tns } from "tiny-slider/src/tiny-slider.module";
import { EventBus } from "@/main";
import firebase, { initializeApp } from "firebase/app";
import "firebase/database";
import FirebasePreload from "@/mixins/FirebasePreload";
import lazyLoader from "@/mixins/lazyLoader";
// import FirebaseActions from "@/mixins/FirebaseActions";
import { stringToDate, checkEventEnded } from "@/mixins/dateSupport.js";

let responsiveData = {
  1900: {
    edgePadding: 0,
    gutter: 20,
    items: 4,
  },
  1400: {
    edgePadding: 0,
    gutter: 20,
    items: 4,
  },
  1200: {
    edgePadding: 0,
    gutter: 32,
    items: 4,
  },
  992: {
    edgePadding: 0,
    gutter: 35,
    items: 3,
  },
  769: {
    items: 3,
    edgePadding: 0,
    gutter: 25,
  },
  576: {
    edgePadding: 0,
    gutter: 15,
    items: 2.1,
  },
  320: {
    edgePadding: 0,
    gutter: 10,
    items: 2.1,
  },
};

export default {
  name: "SliderCarousel",
  props: ["category"],
  data() {
    return {
      contents: [],
      showCardDetails: false,
      showSeeAllBtn: false,
      showSlide: false,
      showInfo: true,
      showDetailCard: true,
      title: "",
      page: 0,
      slider: "",
      contentCount: 0,
      showLoading: true,
      noOfDisplay: null,
      isContinueWatching: false,
      controlsPosition: null,
      defaultLandscapePoster: require("@/assets/icons/placeholder-landscape.png"),
      defaultPotraitPoster: require("@/assets/icons/placeholder-potrait.png"),
      createDetailsCard: false,
      lazyObserver: null,
      sliderInfo: null,
      buttonTop: "40%",
      sliderArrowLeft: false,
      sliderArrowRight: false,
      contentDisplayCount: null,
      mobileView: false,
      placeholdergutter:10,
      currentBreakpoint:null
    };
  },
  computed: {
    ...mapGetters(["sliderCategory", "subscriberId", "contentType","appConfig"]),
    sliderName() {
      if (this.category.title) {
        return this.category.title.toLowerCase().replace(/ /g, "-");
      }
      else return '';
    },
    defaultImage() {
      return this.category.displayType === "LANDSCAPE"
        ? this.defaultLandscapePoster
        : this.defaultPotraitPoster;
    },
  },
  watch: {
    subscriberId() {
      if (this.category.title == "Continue Watching") {
        this.fetchContinueWatching();
        this.isContinueWatching = true;
      }
      if (this.category.title == "Purchased") {
        this.fetchPurchased();
        this.isPurchased = true;
      }
    },
    slider() {
      if (this.slider && this.$refs.sliderElement) {
        let tnsInner = this.$refs.sliderElement.querySelector(".tns-inner");
        if (tnsInner) tnsInner.style = "";
      }
    },
  },
  methods: {
    ...mapMutations(["commitSliderCategory"]),
    ...mapActions(["actListContents",'actGetTicket']),
    showAllCB() {

      if (window.innerWidth > 768) {
        this.mobileView = false;
        if (this.slider && this.slider.getInfo) {
          var info = this.slider.getInfo();
          let tempCount = info.index + this.contentDisplayCount;
          if (tempCount === this.contents.length) {
            this.sliderArrowRight = false;
          } else {
            this.sliderArrowRight = true;
          }
        } else {
          this.sliderArrowLeft = false;
          if (this.contents.length + 1 > this.contentDisplayCount)
            this.sliderArrowRight = true;
        }
      } else {
        this.mobileView = true;
        this.sliderArrowLeft = false;
        this.sliderArrowRight = false;
      }


      // Object.keys(responsiveData).forEach((element) => {
      //   if (window.innerWidth >= element) {
      //     this.contentDisplayCount = responsiveData[element].items;
      //     if (this.contents.length > responsiveData[element].items) {
      //       this.showSeeAllBtn = true;
      //     } else {
      //       this.showSeeAllBtn = false;
      //     }
      //   }
      // });
    for(let [breakpoint,data] of Object.entries(responsiveData).reverse()){
      let width=window.screen && window.screen.width;
      if(width>=Number(breakpoint)){
        this.noOfDisplay=data.items;
        this.placeholdergutter=data.gutter;
        this.contentDisplayCount = data.items;
        if (this.contents.length > data.items) {
          this.showSeeAllBtn = true;
        } else {
          this.showSeeAllBtn = false;
        }

        if(this.currentBreakpoint && (this.currentBreakpoint!==breakpoint) && this.slider && this.slider.getInfo){
          let currentIndex=this.slider.getInfo().index;
          this.slider.destroy();
          this.showSlide=false;
          Promise.resolve().then(this.sliderMovement.bind(this,currentIndex));
        }

        this.currentBreakpoint=breakpoint;

        break;
      }
    }
 
    },
    positionButtons(height) {
      if (height && !isNaN(Number(height))) {
        this.buttonTop = `${Math.round(Number(height) / 2)}px`;
      }
    },
    fetchContents(counterIndex) {
      if( ( this.title==='Continue Watching') || 
          ( this.title==='Purchased')
        ){
          return false;
        }
      this.page++;
      this.contentCount += 15;
       if (this.page === 1) {
      this.showSlide = false;
       }
      let params = {};
      params = this.category.parameters;
      params.page = this.page;
      this.showLoading = true;

      this.actListContents(params)
        .then((response) => {
          this.showLoading = false;

          if (response && response.length) {
            if (this.contents.length > 1) {
              this.contents = [...this.contents, ...response];
            } else {
              this.contents = response;
            }
            this.showSlide = true;

            if (this.page === 1) {
              this.sliderMovement();
            } else {
              this.slider.destroy();
              this.showSlide=false;
              Promise.resolve().then(this.sliderMovement.bind(this,counterIndex));
            }
          }
        })
        .catch((error) => {
          this.showLoading = false;
          if(!this.contents)this.showSlide = false;
          console.log("error");
        });
    },
    fetchPurchased(){
      if(this.category.title !== 'Purchased')return false;
      if (!this.subscriberId) return false;
      this.showLoading=true;
      this.actGetTicket()
        .then(response => {
          if(response && response.error)throw new Error(response.error);
          let purchasedContents=[];
          response.forEach(element => {
            if ( ( element.status === "ACTIVE") && !checkEventEnded(element.expiry) ){
                purchasedContents.push(element);
            }
          });
          this.contents=[];
          this.contents.push(...purchasedContents);
          this.showSlide=true;
          this.sliderMovement();
        })
        .catch(error => {
          console.log(error);
          this.showSlide=false;
          this.showLoading=false;
        });  
    },
    fetchContinueWatching() {
      if (this.category.title !== "Continue Watching") return false;
      if (!this.subscriberId) return false;
      this.showSlide = false;
      let param = {};
      param.continuewatching = true;
      let responseData = [];
      let duration;
      let watched;
      let progress;
      this.showLoading = true;
      this.fetchAllWatchlistContentFromFireBase(param)
        .then((response) => {
          if (response && response.length) {
            response.forEach((element) => {
              duration = element.duration;
              watched = element.watchedduration;
              progress = (watched / duration) * 100;
              //if (progress > 1) {
              element.progress = progress;
              //}
              responseData.push(element);
            });
          }

          return this.fetchAllWatchlistSeriesFromFireBase(param);
          
        }).then((result) => {
            this.showLoading = false;
            result.forEach((elementSeries) => {
              duration = elementSeries.duration;
              watched = elementSeries.watchedduration;
              progress = (watched / duration) * 100;
              // if (progress > 1) {
              elementSeries.progress = progress;
              //}
              responseData.push(elementSeries);
            });

            this.contents = responseData.sort((cont1, cont2) => {
              return stringToDate(cont1.startdate) <
                stringToDate(cont2.startdate)
                ? 1
                : -1;
            });

            
            this.showSlide = true;
            this.category.seeAllLink = "CONTINUEWATCHING";
            this.sliderMovement();
            
        })
        .catch((error) => {
          this.showSlide = false;
          this.showLoading = false;
        });
    },
    sliderMovement(flag = false) {
      if(!this.contents.length){
          this.showSlide = false;
          this.showLoading = false;
          return 'nocontents';
      }
      if(this.slider?.destroy){
         this.showSlide=false;
         this.slider.destroy(); 
         this.$nextTick(()=>{
           this.createSlider();
         })
      }
      else this.createSlider(flag);

    },
    createSlider(flag){
      let self = this;
      this.showSlide=true;
      this.$nextTick(() => {
        this.slider = tns({
          container: `#car-slider-${this.sliderName}`,
          controlsContainer: `#slide-${this.sliderName}-controls`,
          mouseDrag: true,
          nav: false,
          loop: false,
          slideBy: 1,
          gutter: 15,
          responsive: responsiveData,
          preventScrollOnTouch: 'forced'
        });
        flag ? this.slider.goTo(flag) : "";
        this.sliderInfo = this.slider.getInfo();
        this.showAllCB();
        
        this.slider.events.on("touchMove", function (info) {
          if (info.displayIndex + (info.items - 1) === self.contentCount) {
            self.fetchContents(info.index);
          }
        });
        this.slider.events.on("touchStart", function (info) {
          if (info.displayIndex + (info.items - 1) === self.contentCount) {
            self.fetchContents(info.index);
          }
        });
        this.slider.events.on("touchEnd", function (info) {
          if (info.displayIndex + (info.items - 1) === self.contentCount) {
            self.fetchContents(info.index);
          }
        });
      });
    },
    actRightArrow() {
      this.sliderArrowLeft = true;
      var info = this.slider.getInfo();
      if (info.displayIndex + (info.items - 1) === this.contentCount) {
        this.fetchContents(info.displayIndex);
      }
      let tempCount = info.displayIndex + this.contentDisplayCount;
      if (tempCount === this.contents.length && this.contents.length !== 15) {
        this.sliderArrowRight = false;
      }
    },
    actLeftArrow() {
      this.sliderArrowRight = true;
      var info = this.slider.getInfo();
      if (info && info.index === 1) {
        this.sliderArrowLeft = false;
      }
    }
  },
  mounted() {
    window.addEventListener("resize", this.showAllCB);
  },
  beforeDestroy() {
    EventBus.$off("deleteContent", this.fetchContinueWatching);
    EventBus.$off("firebaseUpdated", this.fetchContinueWatching);
    EventBus.$off('purchaseDetailsUpdated',this.fetchPurchased);
    window.removeEventListener("resize", this.showAllCB);
    this.slider && this.slider.destroy();
  },
  created() {    
    let width=window.screen && window.screen.width;
    for(let [breakpoint,data] of Object.entries(responsiveData).reverse()){
      if(width>=Number(breakpoint)){
        this.noOfDisplay=data.items;
        this.placeholdergutter=data.gutter;
        break;
      }
    }

    
    this.title = this.category?.title;
    this.contents = []; 
    if (this.title === "Continue Watching") {
      this.isContinueWatching = true;
      this.fetchContinueWatching();
    }
    else if(this.title === "Purchased"){
      this.isPurchased=true;
      this.fetchPurchased();
      EventBus.$on('purchaseDetailsUpdated',this.fetchPurchased);
    }
    else{
      this.fetchContents();
    }

    this.lazyObserver = this.getObserver();

    EventBus.$on("deleteContent", this.fetchContinueWatching);

    EventBus.$on("firebaseUpdated", this.fetchContinueWatching);
    
  },
  components: {
    "content-card": () =>
      import(
        /* webpackChunkName: "contentCard" */ "@/components/Shared/templates/contentCard.vue"
      ),
    Placeholder: () =>
      import(
        /* webpackChunkName: "Placeholder" */ "@/components/Home/homePlaceholder.vue"
      ),
  },
  mixins: [FirebasePreload, lazyLoader],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.vlt-div-title {
  padding-left: 0;
}

.slider-div {
  // border-bottom: 1px solid $clr-light-hlt-1;
  .sliders-container .content-container:last-of-type & {
    border-bottom: 0px;
  }
}
.slider-carousel {
  margin: 0px;
  position: relative;
}
.slider-head {
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2%;
}
.controls {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  width: calc(var(--max-width) + var(--btn-space));
  //top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:focus {
    outline: none;
  }

  :hover {
    cursor: pointer;
  }
}
.section-title {
  line-height: 36px;
  height: 36px;
  .sec-title {
    color: $font-clr-gray;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: 1.5rem;
    line-height: normal;
    display: inline;
    vertical-align: bottom;
  }
}

.see-all {
  .see-all-link {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    cursor: pointer;
    p {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      color: $font-clr-white-1;
      font-size: 1.25rem;
      text-align: right;
      &:hover {
        color: $font-clr-white;
      }
    }
  }
}

.slide-controls .btn-slide-controls:disabled {
  // display: none;
  opacity: 0.3;
}
.detail-div {
  width: 100%;
  margin-bottom: 10px;
  // margin-top: 2%;
  padding: 0;
}

.down-arrow {
  position: relative;
  top: -6px;
  height: 15px;
  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $theme-primary;
  }
}

.next-img,
.prev-img {
  transition: all 0.3s;
  opacity: 0.6;
  &:hover {
    color: white;
    transform: scale(1.1);
    opacity: 1;
    .a {
      fill: white !important;
    }
  }
  &:active {
    color: white;
    transform: scale(0.9);
    .a {
      fill: white !important;
    }
  }
}

.next-disabled,
.prev-disabled {
  opacity: 0.3;
}

//@media screen and min-width:1200px
@include breakpoint(min1200) {
  .parent-top {
    margin-top: 1rem;
  }
}
// @media screen and (min-width: 1900px) {
@include breakpoint(min1824) {
  // .sec-title {
  //   font-size: 36px;
  // }
  // .see-all .see-all-link p {
  //   font-size: 20px;
  // }
}

// @include breakpoint(mobile){
//   .see-all{
//     padding-right: 5vw;
//   }
// }
</style>