import firebase from "firebase/app";
import "firebase/database";
import { mapGetters} from "vuex";
import appMixins from "@/mixins/appMixins";


const FirebasePreload={  //exposing only specific firebase methods for initial render. invoked in : contentCard, sliderCarousel.

  computed: {
    ...mapGetters(["vendorId", "subscriberId"]),
  },
  methods: {
    fetchAllWatchlistContentFromFireBase(data) {
        return new Promise((resolve) => {
          let pathContent = "content";
  
          let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}`;
          firebase
            .database()
            .ref(path)
            .on("value", snapshot => {
              let snapshotTemp = this.collectionsToArray(snapshot.val());
              let result = []
              if (data.watchlist) {
  
                snapshotTemp.forEach(element => {
                  if (element.inwatchlist) {
                    result.push(element)
                  }
                });
              }
              else if (data.continuewatching) {
                snapshotTemp.forEach(element => {
                  if (element.status === "INPROGRESS" && element.watchedduration && element.duration - element.watchedduration > 0 && element.watchedduration >= 9) {
                    result.push(element)
                  }
                });
              }
              resolve(result);
  
            })
        });
      },
      fetchAllWatchlistSeriesFromFireBase(data) {
        return new Promise((resolve) => {
          let pathContent = "series";
  
          let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}`;
          firebase
            .database()
            .ref(path)
            .on("value", snapshot => {
              let snapshotTemp = this.collectionsToArray(snapshot.val());
              let result = []
              
              if (data.watchlist) {
                snapshotTemp.forEach(element => {
                  if (element.inwatchlist) {
                    element.seriesid=element.contentid;
                    result.push(element)
                  }
                });
              }
              else if (data.continuewatching) {
                // console.log("entered continue", snapshotTemp)
                snapshotTemp.forEach(element => {
                  
                  if (element.episode) {
                    for (const key of Object.keys(element.episode)) {
  
                      let ele = element.episode[key]
                      
                      if (ele.watchedduration && ele.duration - ele.watchedduration >= 9 && ele.watchedduration>=9) {
                        ele.seriesid = element.contentid;
                        ele.expiry = element.expiry;
                        ele.contentid = key;
                        // ele.seriesid = element.contentid
                        ele.startdate=ele.startdate?ele.startdate:element.startdate;
                        result.push(ele)
                      }
                    }
                  }
                });
              }
              resolve(result);
            })
        });
      },
      deleteFirebase(param) {
        return new Promise((resolve) => {
          let pathContent;
          let data = {}
  
          // console.log("param ", param.frompage)
          if (param.frompage === "watchlist") {
            if (param.nodetype === "series") {
              pathContent = `series/${param.seriesid}`
            }
            else {
              pathContent = `content/${param.contentid}`
            }
            data.inwatchlist = false;
          }
          else if (param.frompage === "continuewatching") {
  
            if (param.nodetype === "series") {
              pathContent = `series/${param.seriesid}/episode/${param.contentid}`
            }
            else {
              pathContent = `content/${param.contentid}`
            }
  
            data.status = ""
            data.watchedduration = 0
            data.startdate=null;
          }
          let path = `${this.vendorId}/subscriber/${this.subscriberId}/${pathContent}`;
          firebase
            .database()
            .ref(path)
            .update(data, (err) => {
              if (err) console.log("data could not be removed from watchlist.", err);
              else resolve();
            });
  
        })
      },

  },
  mixins: [appMixins]
}

export default FirebasePreload;