var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.contents && _vm.contents.length ? 'parent-top' : ''},[(_vm.showLoading && !(_vm.contents && _vm.contents.length))?_c('div',{staticClass:"placeholder"},[_c('Placeholder',{attrs:{"title":_vm.title,"noOfDisplay":_vm.noOfDisplay,"gutter":_vm.placeholdergutter}})],1):(_vm.showSlide)?_c('div',{staticClass:"slider-div"},[_c('div',{staticClass:"col-md-6 col-lg-6 col-xlg-6 vlt-div-title",class:_vm.category.seeAllLink && _vm.showSeeAllBtn
          ? 'col-xs-6 col-sm-6'
          : 'col-xs-12 col-sm-12'},[_c('div',{staticClass:"section-title"},[_c('h2',{staticClass:"sec-title"},[_vm._v(_vm._s(_vm.title))])])]),(_vm.category.seeAllLink && _vm.showSeeAllBtn)?_c('div',{staticClass:"col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xlg-6",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"see-all"},[_c('router-link',{attrs:{"to":{
            name: 'ContentList',
            params: { contentType: _vm.category.seeAllLink.toLowerCase() },
          }}},[_c('div',{staticClass:"see-all-link"},[_c('p',[_vm._v("See All")])])])],1)]):_vm._e(),_vm._m(0),_c('div',{ref:"sliderElement",staticClass:"slider-carousel"},[_c('div',{staticClass:"slide-controls controls",style:({
          top: _vm.buttonTop,
        }),attrs:{"id":("slide-" + _vm.sliderName + "-controls")}},[_c('div',{staticClass:"btn-slide-controls prev",attrs:{"aria-label":"left button"}},[(!_vm.mobileView)?_c('img',{class:!_vm.sliderArrowLeft ? 'prev-disabled' : 'prev-img',attrs:{"src":require("@/assets/icons/ico-prev.svg"),"alt":"icon-prev","loading":"lazy","disabled":_vm.sliderArrowLeft},on:{"click":_vm.actLeftArrow}}):_vm._e()]),_c('div',{staticClass:"btn-slide-controls next",attrs:{"id":"next","aria-label":"right button"}},[(!_vm.mobileView)?_c('img',{class:!_vm.sliderArrowRight ? 'next-disabled' : 'next-img',attrs:{"src":require("@/assets/icons/ico-next.svg"),"alt":"icon-next","loading":"lazy","disabled":_vm.sliderArrowRight},on:{"click":_vm.actRightArrow}}):_vm._e()])]),_c('div',{staticClass:"car-slider",attrs:{"id":("car-slider-" + _vm.sliderName)}},_vm._l((_vm.contents),function(content,i){return _c('div',{key:content.contentid,staticClass:"car-slide"},[_c('div',[(i === 0)?_c('content-card',{attrs:{"continueWatching":_vm.isContinueWatching,"content":content,"contentId":content.contentid,"orientation":_vm.category.displayType,"showInfo":_vm.showInfo,"showContentDetailEvent":function () { return _vm.showDetail(content); },"lazyObserver":_vm.lazyObserver,"defaultImage":_vm.defaultImage,"positionButtons":_vm.positionButtons}}):_c('content-card',{attrs:{"continueWatching":_vm.isContinueWatching,"content":content,"contentId":content.contentid,"orientation":_vm.category.displayType,"showInfo":_vm.showInfo,"showContentDetailEvent":function () { return _vm.showDetail(content); },"lazyObserver":_vm.lazyObserver,"defaultImage":_vm.defaultImage}})],1)])}),0)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-head"},[_c('div',{staticClass:"slide-head-btns"},[_c('div',{staticClass:"clearfix"})])])}]

export { render, staticRenderFns }